import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Dropdown from "../components/dropdown/Dropdown";
import Stats from "../components/stats/Stats";
import Promo from "../components/promo/Promo";
import LoadingAnimation from "../components/loading/Loading";
import Overall from "../components/stats/Overall";
import {
  getWebSiteInfo,
  getPageInfo,
  postPageInfo,
} from "../services/speedService";
import {
  getDownloadCSV,
} from "../services/report";
import "./report.css";
import Divider from "../components/divider/Divider";
import Error from "../components/errorState/Error";
import isEqual from "react-fast-compare";
import Button from "../components/button/Button";

const ReportPage = ({ websiteToken: propWebsiteToken }) => {
  const location = useLocation();
  const { token: urlWebsiteToken } = useParams();
  const [pageOptions, setPageOptions] = useState([]);
  const [selectedPage, setSelectedPage] = useState();
  const [errorMessage, setErrorMesssage] = useState(null);
  const [isSiteInfoEnabled, setSiteInfoEnabled] = useState(false);
  const [stopRefetching, setStopRefetching] = useState(false);
  const [progress, setProgress] = useState(0);

  const websiteToken = propWebsiteToken || urlWebsiteToken;
  const name = location.state?.name || null;
  const email = location.state?.email || null;

  const mutation = useMutation(postPageInfo, {
    onSuccess: (data) => {
      if (data?.error) {
        setStopRefetching(true);
        setErrorMesssage(`Could not retrieve sitemap from: ${data?.url}`);
      }
      // TODO: Inform user that report is fully ready and email was sent!
    },
  });

  const {
    data: siteInfo,
    isLoading: siteInfoLoading,
    error: siteError,
  } = useQuery(
    "siteResults",
    () => getWebSiteInfo(websiteToken),
    {
      refetchInterval:
        websiteToken && progress !== 100 && !stopRefetching ? 30000 : false, // Refetch every 30 seconds if url is provided
      enabled: isSiteInfoEnabled, // Enable the query only when isSiteInfoEnabled is true
      onSuccess: async (data) => {
        setProgress(data?.progress || 0);
        setPageOptions(data?.pages);
      },
      onError: () => {
        setStopRefetching(true);
      },
    }
  );

  // Use a separate query key for pageInfo to avoid triggering it on every render
  const {
    data: pageData,
    isLoading: pageDataLoading,
    error: pageError,
  } = useQuery(
    ["pageInfo", selectedPage?.id],
    () => getPageInfo(selectedPage?.id),
    { enabled: !!selectedPage?.id } // Only enable the query when selectedPage.id is available
  );

  useEffect(() => {
    // Enable siteResults query on page load
    setSiteInfoEnabled(true);

    // Select the first page by default
    if (pageOptions.length > 0 && !selectedPage) {
      setSelectedPage({
        page: pageOptions[0].label,
        id: pageOptions[0].value,
      });
    }
  }, [pageOptions, selectedPage]);

  useEffect(() => {
    try {
      let data = {
        token: websiteToken,
        email: email,
        name: name,
      };
      mutation.mutate(data);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, []);

  const setHandleChange = (e) => {
    setSelectedPage({
      page: e.label,
      id: e.value,
    });
  };

  if (siteInfoLoading || pageDataLoading) {
    return <LoadingAnimation />;
  }

  if (siteError || pageError) {
    // TODO: Add cutom error page
    return <Error error={siteError || pageError} />;
  }

  return (
    <>
      <Header isWhite={true} />
      <div className="result container">
        {(errorMessage) ?
          <div className="error-container">
            <p className="error-text">{errorMessage}</p>
          </div> :
          <Overall websiteInfo={siteInfo} progress={progress} />
        }
        {progress === 100 && (
          <div className="download-container">
            <Button onClick={() => getDownloadCSV(websiteToken, siteInfo.url)}>Download CSV</Button>
          </div>
        )}
        {progress > 0 && (
          <div className="result-dropdown">
            <Dropdown
              options={pageOptions}
              selected={selectedPage}
              handleChange={setHandleChange}
            />
          </div>
        )}
        {progress > 0 && (
          <div className="result-results">
            <Stats
              websiteInfo={siteInfo}
              mobileStats={pageData?.mobile_results}
              desktopStats={pageData?.desktop_results}
            />
          </div>
        )}
        <Divider />
        <div className="result-promo">
          <Promo
            sub="Trusted by 100+ fast-growing companies"
            title="Grow rapidly with unlimited Webflow design & development"
            text="Power of a full Webflow team at the cost of one employee. Fast turnaround. No contracts or surprises. Cancel anytime."
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default React.memo(ReportPage, isEqual);
