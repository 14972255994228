import React from 'react';
import isEqual  from 'react-fast-compare';
import { useCleanPercentage } from "../../hooks/useCleanPercentage";
import "./pie.css";

const Pie = ({ percentage = 0, overall = false }) => {
  const pct = useCleanPercentage(percentage);
  const r = 33.5;
  const circ = 2 * Math.PI * r;
  const strokePct = circ * ((100 - percentage) / 100);

  const circleAnimation = {
    "--strokeVal": `${pct ? strokePct : 0}`,
    animation: "circle 1.5s ease-out forwards",
  };

  const colour = () => {
    let colors;

    if (overall) {
      colors = ["#20b5e3", "#9966ff", "#5e3f9d"]
    } else {
      colors = ["#20b5e3", "#1186db", "#1959e6"]
    }

    if (pct > 89) {
      return colors[2];
    } else if (pct < 50) {
      return colors[0];
    } else {
      return colors[1];
    }
  };

  return (
    <div className="pie">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <circle
          cx="40"
          cy="40"
          r="40"
          fill={colour()}
          fillOpacity="0.16"
          stroke={colour()}
          strokeOpacity="0.24"
        />
        <circle
          className="animate-circle"
          cx="40"
          cy="40"
          r={r}
          fill="transparent"
          stroke={strokePct !== circ ? colour() : ""}
          strokeWidth={"13px"}
          strokeDasharray={`${circ}px`}
          // strokeDashoffset={`${pct ? strokePct : 0}`}
          style={circleAnimation}
        />
      </svg>
    </div>
  );
};
export default React.memo(Pie, isEqual);
