import { useRef } from "react";
import "./header.css";
import Button from "../button/Button";
import { Link } from "react-router-dom";
import { ArrowRight } from "../Icons";
import Lottie from "react-lottie";
import LogoWhiteLottie from "../../assets/lottie/flowout_logo_V1_white.json";
import LogoBlackLottie from "../../assets/lottie/flowout_logo_V1.json";

const Header = ({ isWhite }) => {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: isWhite ? LogoBlackLottie : LogoWhiteLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const lottieRef = useRef(null);

  const handleMouseEnter = () => {
    if (lottieRef.current) {
      lottieRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (lottieRef.current) {
      lottieRef.current.stop();
    }
  };

  return (
    <header className={`header${isWhite ? " header-white" : ""}`}>
      <Link
        to="/"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="logo"
      >
        <Lottie options={defaultOptions} isStopped={true} ref={lottieRef} />
      </Link>
      {isWhite ? (
        <Button link="https://www.flowout.com/demo-call">
          Get a Demo <ArrowRight />
        </Button>
      ) : (
        ""
      )}
    </header>
  );
};
export default Header;
