import "./label.css";

const Label = ({ children, fieldId }) => {
  return (
    <label htmlFor={fieldId} className="input-label">
      {children}
    </label>
  );
};

export default Label;
