import Label from "../Label/Label";
import "./input.css";

const Input = ({
  type,
  name,
  placeholder,
  label,
  warning,
  warningMsg,
  pattern,
}) => {
  return (
    <div className="form-control">
      {label && <Label fieldId={`${name}Field`}>{label}</Label>}
      <input
        id={`${name}Field`}
        className={`input-field${warning ? " error" : ""}`}
        type={type ? type : "text"}
        name={name}
        placeholder={placeholder}
        pattern={pattern}
      />
      {warning && <span className="warning">{warningMsg}</span>}
    </div>
  );
};

export default Input;
