import React from "react";

import LoadingAnimation from "../../components/loading/Loading";
import { Link } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import Category from "../statsPanel/Category";
import "./stats.css";
import "./overall.css";
import isEqual  from 'react-fast-compare';

const Overall = ({ websiteInfo, progress }) => {
    const getClass = (score) => {
        if (score > 0.89 || score === "N/A") {
            return "bad";
        } else if (score < 0.5) {
            return "avarage";
        } else {
            return "good";
        }
    };

    if (progress === 100) {
        return (
        <div className="result-top max-width-small">
            <h1>Your results are ready!</h1>
            <p>
            You can view results for <br />
            <Link
                className="result-url-link"
                to={websiteInfo?.url}
                target="_blank"
            >
                {websiteInfo?.url}
            </Link>{" "}
            below.
            </p>
            <div className="stats-panel-overall stats-panel-overall-all">
                <h2 className="stats-panel-title">
                    Overall score
                </h2>
                <div className="stats-panel-overall-wrapper">
                    <Category score={websiteInfo?.overall_desktop_score / 100} title="Desktop" overall={true} />
                    <Category score={websiteInfo?.overall_mobile_score / 100} title="Mobile" overall={true} />
                </div>
            </div>
        </div>
        );
    } else if (progress > 0 && progress < 100) {
        return (
            <div className="result-top max-width-small">
                <h1>Your full report is still in progress.</h1>
                <p>
                But you can already view some results for {" "}
                <Link
                    className="result-url-link"
                    to={websiteInfo?.url}
                    target="_blank"
                >
                    {websiteInfo?.url}
                </Link>
                . Check out your scores below!{" "}
                </p>
                <LoadingAnimation></LoadingAnimation>
                <div className="stats-panel-overall stats-panel-overall-all">
                    <h2 className="stats-panel-title">Overall scores</h2>
                    <div className="stats-panel-overall-wrapper">
                        <h2 className="stats-panel-title--small">
                            Desktop:
                            <span
                                className={`is--${getClass(
                                websiteInfo?.overall_desktop_score / 100
                                )}`}
                            >
                                {websiteInfo?.overall_desktop_score !== null
                                ? " " + websiteInfo?.overall_desktop_score
                                : "N/A"}
                            </span>
                        </h2>
                        <h2 className="stats-panel-title--small">
                            Mobile:
                            <span
                                className={`is--${getClass(
                                websiteInfo?.overall_mobile_score / 100
                                )}`}
                            >
                                {websiteInfo?.overall_mobile_score !== null
                                ? " " + websiteInfo?.overall_mobile_score
                                : "N/A"}
                            </span>
                        </h2>
                        <h2 className="stats-panel-title--small">
                            Combined:
                            <span
                            className={`is--${getClass(websiteInfo?.overall_score / 100)}`}
                            >
                            {websiteInfo?.overall_score !== null
                                ? " " + websiteInfo?.overall_score
                                : "N/A"}
                            </span>
                        </h2>
                    </div>
                </div>
            </div>

        );
    } else {
        return (
            <div className="result-top max-width-small">
                <h1>Your report will be ready soon!</h1>
                <p>
                We are generating your report for{" "}
                <Link
                    className="result-url-link"
                    to={websiteInfo?.url}
                    target="_blank"
                >
                    {websiteInfo?.url}
                </Link>
                . This might take up to a few hours, but don't worry - you can
                leave this page, and you will get notified via email when it's
                ready!
                </p>
                <LoadingAnimation></LoadingAnimation>
            </div>
        );
    }
};
export default React.memo(Overall, isEqual);
