import React from "react";
import isEqual from "react-fast-compare";
import "./error.css";

const Error = ({ error }) => {
  return (
    <div className="error">
      <p>{error.message}</p>
    </div>
  );
};

export default React.memo(Error, isEqual);
