import React from "react";
import Pie from "../pie/Pie";
import "./category.css";
import isEqual from 'react-fast-compare';

const Category = ({ score, title, overall = false }) => {
    const getClass = (score) => {
      if (score > 0.89) {
        return "good";
      } else if (score < 0.5) {
        return "bad";
      } else {
        return "avarage";
      }
    };
    return (
        <div className="stats-panel-score">
          <Pie percentage={parseInt(score * 100)} overall={overall} />
          <div className="stats-panel-score-info">
            <span className={`is--${getClass(score)}`}>
              <strong>{parseInt(score * 100)}</strong>
            </span>
            <p>{title}</p>
          </div>
        </div>
    );
};
export default React.memo(Category, isEqual);