import { API } from "./api"
import fileDownload from 'js-file-download';

export const getDownloadCSV = async (token, url) => {
  try {
    const response = await API.get('/report/download/csv', {
        params: {
            token,
        }
    });
    let domain = url.replace(/.+\/\/|www.|\..+/g, '');
    let filename = domain ? `${domain}.csv` : 'report.csv';
    fileDownload(response.data, filename);
  } catch (error) {
    throw new Error('Error fetching website csv.');
  }
}