export const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.00156 13.4016L4.60156 12.0016L8.60156 8.00156L4.60156 4.00156L6.00156 2.60156L11.4016 8.00156L6.00156 13.4016Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const ProgressPending = ({ cls }) => {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cls ? `${cls}` : ""}
    >
      <rect width="160" height="160" rx="80" fill="white" fillOpacity="0.04" />
      <rect
        x="0.5"
        y="0.5"
        width="159"
        height="159"
        rx="79.5"
        stroke="white"
        strokeOpacity="0.16"
      />
      <path
        d="M86.25 72.5C86.25 72.8315 86.3817 73.1495 86.6161 73.3839C86.8505 73.6183 87.1685 73.75 87.5 73.75C87.8315 73.75 88.1495 73.6183 88.3839 73.3839C88.6183 73.1495 88.75 72.8315 88.75 72.5C88.752 70.5115 89.5428 68.605 90.9489 67.1989C92.355 65.7928 94.2615 65.002 96.25 65C98.9013 64.997 101.443 63.9425 103.318 62.0678C105.192 60.1931 106.247 57.6513 106.25 55C106.25 54.6685 106.118 54.3505 105.884 54.1161C105.649 53.8817 105.332 53.75 105 53.75C104.668 53.75 104.351 53.8817 104.116 54.1161C103.882 54.3505 103.75 54.6685 103.75 55C103.748 56.9885 102.957 58.895 101.551 60.3011C100.145 61.7072 98.2385 62.498 96.25 62.5C93.5987 62.503 91.0569 63.5575 89.1822 65.4322C87.3075 67.3069 86.253 69.8487 86.25 72.5Z"
        fill="#10EFE2"
      />
      <path
        d="M91.875 51.25C94.2912 51.25 96.25 49.2912 96.25 46.875C96.25 44.4588 94.2912 42.5 91.875 42.5C89.4588 42.5 87.5 44.4588 87.5 46.875C87.5 49.2912 89.4588 51.25 91.875 51.25Z"
        fill="#10EFE2"
      />
      <path
        d="M113.125 72.5C115.541 72.5 117.5 70.5412 117.5 68.125C117.5 65.7088 115.541 63.75 113.125 63.75C110.709 63.75 108.75 65.7088 108.75 68.125C108.75 70.5412 110.709 72.5 113.125 72.5Z"
        fill="#10EFE2"
      />
      <path
        d="M112.5 52.5C115.261 52.5 117.5 50.2614 117.5 47.5C117.5 44.7386 115.261 42.5 112.5 42.5C109.739 42.5 107.5 44.7386 107.5 47.5C107.5 50.2614 109.739 52.5 112.5 52.5Z"
        fill="#10EFE2"
      />
      <path
        d="M72.1049 57.435C72.4192 57.5395 72.7622 57.515 73.0584 57.3669C73.3546 57.2188 73.58 56.9591 73.6849 56.645L77.4349 45.395C77.5397 45.0801 77.5151 44.7364 77.3664 44.4396C77.2178 44.1428 76.9574 43.9173 76.6424 43.8125C76.3275 43.7077 75.9838 43.7324 75.6871 43.881C75.3903 44.0296 75.1647 44.2901 75.0599 44.605L71.3099 55.855C71.2583 56.0109 71.2379 56.1755 71.2499 56.3394C71.2619 56.5032 71.3061 56.663 71.38 56.8098C71.4538 56.9565 71.5558 57.0873 71.6802 57.1946C71.8046 57.3018 71.9489 57.3835 72.1049 57.435Z"
        fill="#10EFE2"
      />
      <path
        d="M80.0001 65C80.1991 65 80.3953 64.9525 80.5723 64.8614C80.7492 64.7703 80.9019 64.6382 81.0176 64.4762L87.2676 55.7262C87.4453 55.4562 87.5115 55.1279 87.4523 54.8101C87.3932 54.4924 87.2132 54.2099 86.9502 54.0219C86.6873 53.834 86.3617 53.7553 86.0419 53.8022C85.7221 53.8491 85.4329 54.0181 85.2351 54.2737L78.9851 63.0237C78.8518 63.2104 78.7724 63.4303 78.7557 63.6591C78.7391 63.8879 78.7857 64.1169 78.8905 64.321C78.9953 64.5251 79.1543 64.6964 79.35 64.8162C79.5457 64.9359 79.7706 64.9995 80.0001 65Z"
        fill="#10EFE2"
      />
      <path
        d="M104.274 72.7337L95.5237 78.9837C95.3832 79.0762 95.2628 79.1961 95.1698 79.3362C95.0767 79.4762 95.0128 79.6337 94.9821 79.799C94.9513 79.9644 94.9542 80.1342 94.9906 80.2984C95.027 80.4626 95.0962 80.6178 95.1939 80.7546C95.2917 80.8915 95.4161 81.0072 95.5597 81.0948C95.7032 81.1824 95.863 81.2402 96.0294 81.2646C96.1958 81.289 96.3654 81.2796 96.5281 81.2369C96.6908 81.1943 96.8432 81.1192 96.9762 81.0162L105.726 74.7662C105.982 74.5683 106.151 74.2792 106.198 73.9594C106.245 73.6396 106.166 73.314 105.978 73.0511C105.79 72.7881 105.508 72.6081 105.19 72.549C104.872 72.4898 104.544 72.556 104.274 72.7337Z"
        fill="#10EFE2"
      />
      <path
        d="M64.6612 57.895C64.514 57.7384 64.3289 57.6224 64.1238 57.5582C63.9187 57.494 63.7005 57.4837 63.4903 57.5285C63.28 57.5732 63.0849 57.6714 62.9237 57.8135C62.7625 57.9557 62.6407 58.137 62.57 58.34L43.375 113.516C43.2436 113.893 43.2041 114.295 43.26 114.69C43.3158 115.085 43.4652 115.461 43.6958 115.786C43.9265 116.111 44.2315 116.377 44.5856 116.56C44.9397 116.743 45.3325 116.839 45.7312 116.84C45.9794 116.839 46.2261 116.803 46.4637 116.731L101.614 99.9462C101.817 99.8843 102.001 99.7717 102.149 99.6188C102.296 99.466 102.403 99.278 102.457 99.0727C102.512 98.8674 102.514 98.6515 102.462 98.4455C102.41 98.2394 102.307 98.0499 102.161 97.895L64.6612 57.895ZM50.1912 112.989L48.2412 107.141L50.5537 100.5L54.3037 111.742L50.1912 112.989ZM62.6737 109.189L55.2625 86.955L57.575 80.3087L66.7837 107.934L62.6737 109.189ZM75.1575 105.387L62.2825 66.7625L64.25 61.1137L65.2975 62.23L79.2675 104.136L75.1575 105.387ZM87.64 101.587L79.61 77.5L86.635 85L91.75 100.336L87.64 101.587Z"
        fill="#9966FF"
      />
      <path
        d="M114.605 82.565L103.355 86.315C103.199 86.3669 103.055 86.449 102.931 86.5566C102.806 86.6642 102.705 86.7952 102.631 86.9421C102.557 87.0891 102.513 87.2491 102.502 87.413C102.49 87.5769 102.511 87.7416 102.563 87.8975C102.614 88.0535 102.696 88.1977 102.804 88.3219C102.912 88.4461 103.043 88.5479 103.19 88.6215C103.337 88.6951 103.497 88.739 103.661 88.7508C103.824 88.7625 103.989 88.7419 104.145 88.69L115.395 84.94C115.551 84.8881 115.695 84.8061 115.819 84.6985C115.944 84.5909 116.045 84.4598 116.119 84.3129C116.193 84.1659 116.237 84.0059 116.248 83.842C116.26 83.6781 116.239 83.5135 116.188 83.3575C116.136 83.2016 116.054 83.0574 115.946 82.9331C115.838 82.8089 115.707 82.7071 115.56 82.6335C115.413 82.5599 115.253 82.516 115.09 82.5042C114.926 82.4925 114.761 82.5131 114.605 82.565Z"
        fill="#10EFE2"
      />
    </svg>
  );
};

export const MobileResult = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.5 1H18.5C19.0304 1 19.5391 1.21071 19.9142 1.58579C20.2893 1.96086 20.5 2.46957 20.5 3V21C20.5 21.5304 20.2893 22.0391 19.9142 22.4142C19.5391 22.7893 19.0304 23 18.5 23H6.5C5.96957 23 5.46086 22.7893 5.08579 22.4142C4.71071 22.0391 4.5 21.5304 4.5 21V3C4.5 2.46957 4.71071 1.96086 5.08579 1.58579C5.46086 1.21071 5.96957 1 6.5 1Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12.5 20C13.0523 20 13.5 19.5523 13.5 19C13.5 18.4477 13.0523 18 12.5 18C11.9477 18 11.5 18.4477 11.5 19C11.5 19.5523 11.9477 20 12.5 20Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const DesktopResult = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M12.5 22.5V18.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M6.5 22.5H18.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M23.5 2.5H1.5V18.5H23.5V2.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g opacity="0.4">
        <path
          d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.14583 15.3708 4.8875 14.1125C3.62917 12.8542 3 11.3167 3 9.5C3 7.68333 3.62917 6.14583 4.8875 4.8875C6.14583 3.62917 7.68333 3 9.5 3C11.3167 3 12.8542 3.62917 14.1125 4.8875C15.3708 6.14583 16 7.68333 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8125 13.5625 12.6875 12.6875C13.5625 11.8125 14 10.75 14 9.5C14 8.25 13.5625 7.1875 12.6875 6.3125C11.8125 5.4375 10.75 5 9.5 5C8.25 5 7.1875 5.4375 6.3125 6.3125C5.4375 7.1875 5 8.25 5 9.5C5 10.75 5.4375 11.8125 6.3125 12.6875C7.1875 13.5625 8.25 14 9.5 14Z"
          fill="#536778"
        />
      </g>
    </svg>
  );
};

export const DropdownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 7.62502L6 13.625L7.4 15.025L12 10.425L16.6 15.025L18 13.625L12 7.62502Z"
        fill="black"
      />
    </svg>
  );
};
