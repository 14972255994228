import React from "react";
import "./statsPanel.css";
import triIcon from "../../assets/img/triangle.svg";
import circleIcon from "../../assets/img/circle.svg";
import squareIcon from "../../assets/img/square.svg";
import isEqual  from 'react-fast-compare';

const Audit = ({ data }) => {
    const getScoreAudit = (score) => {
        if (score > 0.89) {
            return "bad";
        } else if (score < 0.5) {
            return "avarage";
        } else {
            return "good";
        }
    };

    const getIcon = (score) => {
        if (score > 0.89) {
            return triIcon;
        } else if (score < 0.5) {
            return squareIcon;
        } else {
            return circleIcon;
        }
    };
    return (
        <div className="stats-panel-issue">
            <img
                src={getIcon(data?.score)}
                alt=""
                loading="lazy"
            />
            <div className="stats-panel-issue-content">
                <p>{data?.title}</p>
                <span className={`is--${getScoreAudit(data?.score)}`}>
                    {data?.displayValue}
                </span>
            </div>
        </div>
    );
};
export default React.memo(Audit, isEqual);