import React from "react";
import indicatorImg from "../../assets/img/indicator.svg";
import uuid from "react-uuid";
import "./stack-bar.css";
import isEqual  from 'react-fast-compare';

const StackBar = ({percentile, display_value, distributions, category}) => {
  const getColor = (i) => {
    if (i === 0) {
      return 'bad';
    } else if (i === 1) {
      return 'avarage';
    } else {
      return 'good'
    }
  }
  const getLeftPosition = (distributions, percentile) => {
    let leftPosition;
    // Calculate the total proportion covered by the first two distributions
    const matchingDistribution = distributions.find(distribution => {
      return distribution.min <= percentile && percentile <= distribution.max;
    }) ?? distributions[2];

    let startingPercentage = 0;

    if (matchingDistribution.max) {
      // Calculate the percentile's position within the first two distributions
      if (matchingDistribution.min > 0) {
        startingPercentage = distributions[0].proportion;
      }

      const rangeSize = matchingDistribution.max - matchingDistribution.min;
      const percSize = percentile - matchingDistribution.min;
      const position = (percSize * 100) / rangeSize;
      const rangeProp = matchingDistribution.proportion;
      const addToStart = (position * 0.01) * rangeProp;
      leftPosition = (startingPercentage + addToStart) * 100;
    } else {
      // Calculate the percentile's position within the third distribution
      startingPercentage = distributions[0].proportion + distributions[1].proportion;
      const position = 50;
      const rangeProp = matchingDistribution.proportion;
      const addToStart = (position * 0.01) * rangeProp;
      leftPosition = (startingPercentage + addToStart) * 100;
    }

    return leftPosition;
  }
  const getDistribution = (category) => {
    if (category === "FAST") {
      return 2
    } else if (category === "AVERAGE") {
      return 1
    } else {
      return 0
    }
  }

  return (
    <>
      <div className="stack-bar">
        <div className="stack-bar-bar">
          {category === 'N/A'
            ? <div
                className={`stack-bar-part stack-bar-part--grey`}
                style={{width: '100%'}}
              ></div>
            : distributions
              ? distributions.map((el, i) => (
                <div
                  className={`stack-bar-part stack-bar-part--${getColor(i)}`}
                  style={{width: display_value === 'N/A' ? '100%' : `${el.proportion * 100}%`}}
                  key={uuid()}
                ></div>
              ))
              : <div
                  className={`stack-bar-part stack-bar-part--bad`}
                  style={{width: '100%'}}
                ></div>
          }
        </div>

        <div className="stack-bar-indicator" style={{left: `${getLeftPosition(distributions, percentile)}%`}}>
          <img src={indicatorImg} alt="" />
          {category === "N/A"
            ? <span
                className={`stack-bar-text stack-bar-text--na`}
              >{display_value ?? category}</span>
            : <span
                className={`stack-bar-text stack-bar-text--${getColor(getDistribution(category))}`}
              >{display_value ?? category}</span>
          }
        </div>
      </div>
    </>
  )
}
export default React.memo(StackBar, isEqual);