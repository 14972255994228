import clinetsImg from "../../assets/img/client-avatars-95.png"; // replace image (now used from https://www.flowout.com/webflow-guides/the-ultimate-saas-landing-page-checklist#cta-form)
import Button from "../button/Button";
import "./promo.css";
import { ArrowRight } from "../Icons";

const Promo = ({ size = "big", sub, title, text }) => {
  return (
    <div className={`promo promo--${size}`}>
      <div className="promo-companies">
        <img
          src={clinetsImg}
          alt="More then 50 trusted companies"
          loading="lazy"
        />
        <div className="promo-companies-text">{sub}</div>
      </div>
      <h2 className={`promo-title promo-title-${size}`}>{title}</h2>
      <p className="promo-text">{text}</p>
      <div className="promo-ctas">
        <Button link="https://www.flowout.com/demo-call">
          Get a demo <ArrowRight />
        </Button>
        {size === "big" && (
          <Button type="secondary" link="https://www.flowout.com/pricing">
            See pricing <ArrowRight />
          </Button>
        )}
      </div>
    </div>
  );
};
export default Promo;
