import React, { useState } from "react";
import Button from "../button/Button";
import Input from "./Input/Input";
import { ArrowRight } from "../Icons";
import LoadingAnimation from "../loading/Loading";
import isEqual from "react-fast-compare";

const SitemapForm = ({ className, onSubmitForm, isLoading }) => {
  const [urlWarning, setUrlWarning] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    // Check if "url" and "email" are provided
    if (!data.url || !data.email) {
      setUrlWarning(!data.url);
      setEmailWarning(!data.email);
      return;
    }

    onSubmitForm(data);
  };

  const handleInputChange = (fieldName) => {
    if (fieldName === "url") {
      setUrlWarning(false);
    } else if (fieldName === "email") {
      setEmailWarning(false);
    }
  };

  return (
    <form className={`form ${className}`} onSubmit={handleFormSubmit}>
      <div className="form-fields">
        <Input
          name="url"
          placeholder="Enter your website here..."
          label="Website"
          type="url"
          pattern="https?://.*"
          required
          warning={urlWarning}
          warningMsg="URL is required or not correct"
          onChange={() => handleInputChange("url")}
        />
        <Input
          name="email"
          type="email"
          placeholder="email@company.com"
          label="Email"
          required
          warning={emailWarning}
          warningMsg="Email is required"
          onChange={() => handleInputChange("email")}
        />
        <Input name="name" placeholder="Enter your name..." label="Name" />
      </div>
      <div className="form-actions">
        <Button type="submit" disabled={isLoading}>
          {isLoading ? "Loading..." : "Get your score"} <ArrowRight />
        </Button>
      </div>
      {isLoading && <LoadingAnimation />}
    </form>
  );
};

export default React.memo(SitemapForm, isEqual);
