import { API } from "./api"

export const postWebSiteInfo = async ({url, email, name}) => {
  try {
    const response = await API.post('/website/store', {
        url: url,
        email: email,
        name: name,
    });
    return response.data;
  } catch (error) {
    throw new Error('Something went wrong.');
  }
}

export const postPageInfo = async ({token, email, name}) => {
  try {
    const response = await API.post('/page/store', {
        token: token,
        email: email,
        name: name,
    });
    return response.data;
  } catch (error) {
    throw new Error('Something went wrong.');
  }
}

export const getWebSiteInfo = async (token) => {
  try {
    const response = await API.get('/website/index/token', {
        params: {
            token,
        }
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching website data.');
  }
}

export const getWebSiteProgress = async (url) => {
  try {
    const response = await API.get('/website/progress', {
        params: {
            url,
        }
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching website progress.');
  }
}

export const getWebSiteToken = async (url) => {
  try {
    const response = await API.get('/website/token', {
        params: {
            url,
        }
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching website token.');
  }
}

export const getPageInfo = async (id) => {
  try {
    const response = await API.get('/page/index', {
        params: {
            id,
        }
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching page data.');
  }
}