import "./assets/css/App.css";
import { AllRoutes } from "./Routes";
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <AllRoutes />
      </div>
    </QueryClientProvider>
  );
}

export default App;
