import React from "react";
import Promo from "../promo/Promo";
import vitalsImg from "../../assets/img/vitals.svg";
import Audit from "./Audit";
import Metric from "./Metric";
import Category from "./Category";
import uuid from "react-uuid";
import { calculateMetricScore } from "./helpers/index";
import "./statsPanel.css";
import isEqual from "react-fast-compare";

const StatsPanel = ({ websiteInfo, stats, getClassFn }) => {
  const getClass = (score) => {
    if (score > 0.89 || score === "N/A") {
      return "bad";
    } else if (score < 0.5) {
      return "avarage";
    } else {
      return "good";
    }
  };

  const getMetricClass = (score) => {
    if (score === "SLOW") {
      return "bad";
    } else if (score === "AVERAGE") {
      return "avarage";
    } else {
      return "good";
    }
  };

  return (
    <div className="stats-panel">
      <div className="stats-panel-overall">
        <h3 className="stats-panel-title">
          Page score:
          <span className={`is--${getClass(stats?.overall_score / 100)}`}>
            {stats?.overall_score !== null ? " " + stats?.overall_score : "N/A"}
          </span>
        </h3>
      </div>
      <div className="stats-panel-scores">
        {stats?.categories?.map(
          (category) =>
            category.title !== "PWA" && (
              <Category score={category?.score} title={category?.title} key={uuid()} />
            )
        )}
      </div>
      <div className="stats-panel-bottom">
        <div className="stats-panel-promo">
          <Promo
            size="small"
            sub="Trusted by 100+ companies"
            title="Add a power of a Webflow team to your business without breaking the budget"
            text="Replace interviews and hiring with experienced Webflow developers and designers."
          />
        </div>
        <div className="stats-panel-extra">
          {stats?.audits?.length > 0 && (
            <div className="stats-panel-issues">
              <h3 className="stats-title">Diagnose performance issues</h3>
              <div className="stats-border">
                <div className="stats-panel-extra-stats">
                  {stats?.audits?.map((audit, i) => (
                    <Audit data={audit} key={uuid()} />
                  ))}
                  {stats?.audits?.length % 2 !== 0 && ( // add empty div so the number is even
                    <div className="stats-panel-issue"></div>
                  )}
                </div>
              </div>
            </div>
          )}

          {stats?.metrics?.length > 0 && (
            <div className="stats-panel-experience">
              <h3 className="stats-title">
                Discover what your real users are experiencing
              </h3>
              <div className="stats-border">
                <h4 className="stats-panel-exp-vital-title">
                  <img src={vitalsImg} alt="" loading="lazy" />
                  Core Web Vitals Assessment:{" "}
                  <span
                    className={`is--${getMetricClass(
                      calculateMetricScore(stats?.metrics)
                    )}`}
                  >
                    {calculateMetricScore(stats?.metrics)}
                  </span>
                </h4>
                <div className="stats-panel-extra-stats">
                  {stats?.metrics?.map((metric) => (
                    <Metric data={metric} key={uuid()} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(StatsPanel, isEqual);
