import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import SitemapForm from "../components/form/SitemapForm";
import imgLeft from "../assets/img/graphic-left.svg";
import imgRight from "../assets/img/graphic-right.svg";
import { postWebSiteInfo } from "../services/speedService";
import "./home.css";
import isEqual from "react-fast-compare";

const HomePage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);

  const mutation = useMutation(postWebSiteInfo, {
    onSuccess: (data) => {
      navigate("/report/" + data.url_token, { state: { email, name } });
    },
  });

  const onSubmitForm = async (data) => {
    await setEmail(data.email);
    await setName(data.name);
    await mutation.mutateAsync(data);
  };

  const { isLoading, error } = mutation;

  return (
    <div className="page overflow-hidden">
      <Header />
      <div className="home-full-height">
        {error && <div>error</div>}

        <div className="home-container max-width-small">
          <div className="home-header">
            <h1>Test your Webflow website performance</h1>
            <p className="home-header-sub">
              Get PageSpeed Insights scores and Core Web Vitals for your whole
              Webflow website by submitting only one form.
            </p>
          </div>
          <div className="home-form">
            <SitemapForm
              className="form-dark"
              onSubmitForm={onSubmitForm}
              isLoading={isLoading}
            />
          </div>
        </div>
        <img src={imgLeft} className="bg-img-left" alt="" loading="lazy" />
        <img src={imgRight} className="bg-img-right" alt="" loading="lazy" />
      </div>
    </div>
  );
};

export default React.memo(HomePage, isEqual);
