import React, { useState } from "react";
import isEqual from "react-fast-compare";
import Select, { components } from "react-select";
import { SearchIcon, DropdownIcon } from "../Icons";
import "./dropdown.css";

const Dropdown = ({ options, selected, handleChange }) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const handleThisChange = (e) => {
    handleChange(e);
    setSelectOpen(false);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="page-select">
      <button
        className={`page-select-top${selectOpen ? " is-open" : ""}`}
        onClick={() => setSelectOpen(!selectOpen)}
      >
        <p>
          <strong>Page:</strong> {selected?.page}
        </p>
        <DropdownIcon />
      </button>
      {selectOpen && (
        <div className="page-select-bottom">
          <Select
            options={options}
            isSearchable
            menuIsOpen
            onChange={handleThisChange}
            placeholder="Search page..."
            classNamePrefix="react-select-custom"
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
    </div>
  );
};
export default React.memo(Dropdown, isEqual);
