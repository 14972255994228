import React from "react";
import "./statsPanel.css";
import triIcon from "../../assets/img/triangle.svg";
import circleIcon from "../../assets/img/circle.svg";
import squareIcon from "../../assets/img/square.svg";
import StackBar from "../stackBar/StackBar";
import isEqual  from 'react-fast-compare';

const Metric = ({ data }) => {
  const getIcon = (distributions, percentile) => {
    if (percentile <= distributions[0].max) {
      return circleIcon;
    } else if (percentile <= distributions[1].max) {
      return squareIcon;
    } else {
      return triIcon;
    }
  };
  return (
    <div className="stats-panel-exp-container">
      <div className="stats-panel-exp">
        <img
          src={getIcon(data?.distributions, data?.percentile)}
          alt=""
          className="stats-panel-exp-icon"
          loading="lazy"
        />
        <div className="stats-panel-exp-content">
          <p>{data?.title}</p>

          <StackBar
            percentile={data?.percentile}
            display_value={data?.displayValue}
            distributions={data?.distributions}
            category={data?.category}
          />
        </div>
      </div>
    </div>
  );
};
export default React.memo(Metric, isEqual);
