export const calculateMetricScore = (metrics) => {
  // Mapping string values to numeric values
  const scoreValues = metrics.map((metric) => {
    switch (metric.category) {
      case 'FAST':
        return 3; // Assigning a numeric value for 'FAST'
      case 'AVERAGE':
        return 2; // Assigning a numeric value for 'AVERAGE'
      case 'SLOW':
        return 1; // Assigning a numeric value for 'SLOW'
      default:
        return 0; // Default value for unknown input
    }
  });

  // Calculating the average
  const sum = scoreValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const average = sum / scoreValues.length;

  // Mapping numeric average back to string value
  let result;
  if (average >= 2.5) {
    result = 'FAST';
  } else if (average >= 1.5) {
    result = 'AVERAGE';
  } else {
    result = 'SLOW';
  }

  return result;
};