import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import StatsPanel from "../statsPanel/StatsPanel";
import { MobileResult, DesktopResult } from "../../components/Icons";
import "react-tabs/style/react-tabs.css";
import "./stats.css";
import isEqual  from 'react-fast-compare';

const Stats = ({ websiteInfo, mobileStats, desktopStats }) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Tabs
      className="stats"
      selectedIndex={tabIndex}
      onSelect={(index) => setTabIndex(index)}
    >
      <TabList className="stats-tabs">
        <Tab>
          <MobileResult />
          <span>Mobile</span>
        </Tab>
        <Tab>
          <DesktopResult />
          <span>Desktop</span>
        </Tab>
      </TabList>

      <div className="stats-results">
        <TabPanel>
          <StatsPanel websiteInfo={websiteInfo} stats={mobileStats} />
        </TabPanel>
        <TabPanel>
          <StatsPanel websiteInfo={websiteInfo} stats={desktopStats} />
        </TabPanel>
      </div>
    </Tabs>
  );
};
export default React.memo(Stats, isEqual);
