import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home";
import { NotFoundPage } from "./pages/NotFound";
// import { ProgressPage } from "./pages/Progress";
import ReportPage from "./pages/Report";

export const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        {/* <Route path="/progress" element={<ProgressPage />}></Route> */}
        <Route path="/report/:token" element={<ReportPage />}></Route>
        <Route element={<NotFoundPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
