import React from 'react';
import isEqual  from 'react-fast-compare';
import { Link } from "react-router-dom";
import "./button.css";

const Button = ({ children, cls, link, type = "primary", onClick }) => {
  return link
    ? <Link to={link} className={`btn${cls ? " " + cls : ""} btn-${type}`}>{children}</Link>
    : <button className={`btn${cls ? " " + cls : ""}`} onClick={onClick}>{children}</button>
};

export default React.memo(Button, isEqual);
