import React, { useEffect } from "react";
import isEqual from "react-fast-compare";
import logo from "../../assets/img/flowout-logo-black.svg";
import trustpilotImg from "../../assets/img/trustpilot.svg";
import twitterLogo from "../../assets/img/twitter.svg";
import linkedinLogo from "../../assets/img/linkedin.svg";
import { Link } from "react-router-dom";
import "./footer.css";
import Divider from "../divider/Divider";

const Footer = () => {
  useEffect(() => {
    // Load Clutch widget script dynamically
    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src = "https://widget.clutch.co/static/js/widget.js";
    document.head.appendChild(script);

    script.onload = () => {
      if (window.CLUTCHCO) {
        window.CLUTCHCO.Init();
      }
    };

    return () => {
      // Cleanup
      document.head.removeChild(script);
    };
  }, []);
  return (
    <div className="container">
      <footer className="footer">
        <div className="footer-top">
          <div className="footer-top-left">
            <div className="footer-logo">
              <Link to="/">
                <img src={logo} className="logo" alt="logo" loading="lazy" />
              </Link>
            </div>
            <div className="footer-proof">
              <div>
                <div
                  className="clutch-widget"
                  data-url="https://widget.clutch.co"
                  data-widget-type="2"
                  data-height="45"
                  data-width="200"
                  data-nofollow="true"
                  data-expandifr="true"
                  data-scale="100"
                  data-clutchcompany-id="1919503"
                ></div>
              </div>
              <Link
                to="https://www.trustpilot.com/review/flowout.com"
                target="_blank"
              >
                <img
                  src={trustpilotImg}
                  alt="5 star rating on Trustpilot"
                  width="120"
                  height="65.75"
                />
              </Link>
            </div>
          </div>
          <div className="footer-socials">
            <ul aria-label="social links menu">
              <li>
                <Link
                  to="https://twitter.com/FlowoutHQ"
                  className="social-link"
                  target="_blank"
                >
                  <img
                    src={twitterLogo}
                    alt="twitter logo"
                    loading="lazy"
                    width="16"
                    height="16"
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="https://linkedin.com/company/flowout"
                  className="social-link"
                  target="_blank"
                >
                  <img
                    src={linkedinLogo}
                    alt="linkedin logo"
                    loading="lazy"
                    width="16"
                    height="16"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <Divider />
        <div className="footer-bottom">
          <p>©{new Date().getFullYear()} Woice d.o.o. All right reserved.</p>
          <ul aria-label="legal links menu">
            <li>
              <Link
                to="https://www.flowout.com/terms-and-conditions"
                target="_blank"
              >
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link to="https://www.flowout.com/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};
export default React.memo(Footer, isEqual);
